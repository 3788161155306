.App {
    text-align: center;
    background-color: transparent;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: black;
}

.Card-App {
    width: 850px;
    padding: 32px 64px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.LogoHeader {
    width: 100%;
    height: auto;
    padding: 32px 0 32px 0;
}

.LogoHeaderImage{
    flex-shrink: 0;
    width: auto;
    height:auto;
    max-width: 40%;
    max-height: 32px;
    object-fit: contain;
}

/* Add a new rols for Card-App in case of movile screen  */
@media (max-width: 1024px) {
    .Card-App {
        width: 85vw;
        margin: auto;
        min-height: calc(100vh - 100px);
        padding: 16px 20px;
    }
}
@media (max-width: 790px) {
    .Card-App {
        width: auto;
        min-width: 85vw;
        margin: auto;
        min-height: calc(100vh - 32px);
        padding: 16px 25px;
    }

    .LogoHeader{
        padding: 16px 0;
    }

    .LogoHeaderImage{
        max-width: 50%;
    }
}
